import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import ForbiddenImage from 'assets/images/403PageLogo.png';
import Button from 'components/shared/Button';
import styles from './Forbidden403.module.scss';

const Forbidden403: FC = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.root}>
      <p>
        Sorry. the content you’re looking for doesn’t exist
      </p>
      <img src={ForbiddenImage} alt="403 Forbidden Image" />
      <Button variant="primary" onClick={() => navigate('/')}>
        Go to Home
      </Button>
    </div>
  );
};

export default Forbidden403;
