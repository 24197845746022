import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import {
  HolidaysListInitialState,
  IFetchHolidaysParams,
} from './holidaysListTypes';
import {
  getHolidaysList,
  getHolidaysListInfiniteScroll,
  getMemberHolidays,
} from './holidaysListActions';
import { isFulfilledAction, isRejectedAction } from '../sliceHelpers';

const initialState: HolidaysListInitialState = {
  holidays: [],
  filter: { searchValue: '', skip: 0, take: 20 },
  source: 'calendar',
  selectedHolidaysId: -1,
  totalCount: 0,
  isLoading: false,
  errorMessage: '',
};

export const holidaysListSlice = createSlice({
  name: '@@holidaysList',
  initialState,
  reducers: {
    setHolidaysFilter: (state, action: PayloadAction<IFetchHolidaysParams>) => {
      state.filter = action.payload;
    },
    removeHolidayReducer: (state, action: PayloadAction<number>) => {
      state.holidays = state.holidays.filter((h) => h.id !== action.payload);
      state.totalCount -= 1;
    },
    setSelectedHolidayId: (state, action: PayloadAction<number>) => {
      state.selectedHolidaysId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getHolidaysList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getHolidaysList.fulfilled, (state, action) => {
      state.holidays = action.payload.list;
      state.totalCount = action.payload.count;
    });
    builder.addCase(getMemberHolidays.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMemberHolidays.fulfilled, (state, action) => {
      state.holidays = action.payload;
      state.source = 'calendar';
      // state.totalCount = action.payload.length;
    });
    builder.addCase(
      getHolidaysListInfiniteScroll.fulfilled,
      (state, action) => {
        if(state.source === 'holidaysPage') {
          state.holidays.push(...action.payload.list);
        }
        else{
          state.holidays = action.payload.list;
        }
        state.source = 'holidaysPage';
        state.totalCount = action.payload.count;
      }
    );
    builder.addMatcher(isFulfilledAction, (state) => {
      state.errorMessage = '';
      state.isLoading = false;
    });
    builder.addMatcher(isRejectedAction, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    });
  },
});

export const { setHolidaysFilter, removeHolidayReducer, setSelectedHolidayId } =
  holidaysListSlice.actions;
export const holidaysListSelector = createSelector(
  [(state: RootState) => state.holidaysList.holidays],
  (holidays) => holidays
);
export const isLoadingHolidaysListSelector = createSelector(
  [(state: RootState) => state.holidaysList.isLoading],
  (isLoading) => isLoading
);
export const filterHolidaysListSelector = createSelector(
  [(state: RootState) => state.holidaysList.filter],
  (filter) => filter
);
export const itemsTotalCountHolidaysListSelector = createSelector(
  [(state: RootState) => state.holidaysList.totalCount],
  (totalCount) => totalCount
);
export const selectedHolidaysIdSelector = createSelector( 
  [(state: RootState) => state.holidaysList.selectedHolidaysId],
  (id) => id
);

export const selectedHolidaySelector = createSelector(
  [
    holidaysListSelector,
    selectedHolidaysIdSelector
  ],
  (holidays, id) => {
    const holiday = holidays.find((holiday) => holiday.id === id);
    if (holiday) {
      return holiday
    }
  }
);