import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { MyKnownError } from 'features/thunkError';
import { AppDispatch } from 'app/store';
import {
  fetchTimeOffRequestInfo,
  updateTimeOffRequestInfo,
} from 'api/timeOffRequestInfo';
import {
  ITimeOffRequestInfo,
  IUpdateTimeOffRequestData,
} from './timeOffRequestInfoTypes';
import { thunkErrorHandler } from '../thunkError';

export const getTimeOffRequestInfo = createAsyncThunk<
  ITimeOffRequestInfo,
  number,
  {
    dispatch: AppDispatch;
    rejectValue: string | unknown;
  }
>('@@timeOffRequestInfo/getTimeOffRequestInfo', async (requestId, thunkApi) => {
  try {
    const res = await fetchTimeOffRequestInfo(requestId);
    return res.data;
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});

export const editTimeOffRequest = createAsyncThunk<
  void,
  IUpdateTimeOffRequestData,
  {
    rejectValue: string | unknown;
    dispatch: AppDispatch;
  }
>('@@timeOffRequestInfo/editTimeOffRequest', async (data, thunkApi) => {
  try {
    const res = await updateTimeOffRequestInfo(data);
    if (res.status === 200) {
      thunkApi.dispatch(getTimeOffRequestInfo(Number(data.id)));
      toast.success('Time off request has been changed.');
    }
  } catch (err) {
    return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
  }
});
