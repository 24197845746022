import React, { FC } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import ValidationTextInput from 'components/shared/forms/ValidationTextInput';
import Button from 'components/shared/Button';
import {MaxLength100Symbols, MaxLength300Symbols} from 'constants/formsConstants';
import styles from './AddJiraConnectionForm.module.scss';
import {IAddingJiraConnectionData} from "features/jira/jiraTypes";

interface AddJiraConnectionFormProps {
  onSubmit: (data: IAddingJiraConnectionData) => void;
  closeAddJiraConnectionForm: () => void;
}

const AddJiraConnectionForm: FC<AddJiraConnectionFormProps> = ({
  onSubmit,
  closeAddJiraConnectionForm,
}) => {
  const InitialValues: {
    serverUrl: string;
    username: string;
    apiToken: string;
  } = {
    serverUrl: '',
    username: '',
    apiToken: '',
  };
  const validationSchema = Yup.object({
    serverUrl: Yup.string()
      .max(
          MaxLength100Symbols,
        ` *Maximum length ${MaxLength100Symbols} characters`
      )
      .required('Required field'),
    username: Yup.string()
        .max(
            MaxLength100Symbols,
            ` *Maximum length ${MaxLength100Symbols} characters`
        )
        .required('Required field'),
    apiToken: Yup.string()
        .max(
            MaxLength300Symbols,
            ` *Maximum length ${MaxLength300Symbols} characters`
        )
        .required('Required field'),
  });

  return (
    <div className={styles.wrapper}>
      <Formik
        enableReinitialize
        initialValues={InitialValues}
        validationSchema={validationSchema}
        onSubmit={(
          values: typeof InitialValues,
          { setSubmitting, resetForm }
        ) => {
          onSubmit({
            serverUrl: values.serverUrl,
            username: values.username,
            apiToken: values.apiToken,
          });
          setSubmitting(false);
          resetForm();
          closeAddJiraConnectionForm();
        }}
      >
        {(formik) => (
          <Form>
            <ValidationTextInput
              label="Jira URL"
              name="serverUrl"
              type="text"
              placeholder="https://trackera.atlassian.net"
            />

            <ValidationTextInput
                label="Jira email"
                name="username"
                type="text"
                placeholder="Add your Jira email"
            />

            <ValidationTextInput
                label="Jira token"
                name="apiToken"
                type="text"
                placeholder="Add a Jira token"
            />

            <div className={styles.controlWrapper}>
              <Button
                type="button"
                onClick={() => {
                  formik.resetForm();
                  closeAddJiraConnectionForm();
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="primary"
                disabled={formik.isSubmitting}
                preloader={formik.isSubmitting}
              >
                Add
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddJiraConnectionForm;
