import axios from "axios";
import {IAddingJiraConnectionData, IGenerateJiraIssuesDescription} from "../features/jira/jiraTypes";
import {userManager} from "../features/user/userSlice";

const client = axios.create({
    headers: { 'Content-Type': 'application/json' },
    baseURL: process.env.REACT_APP_JIRA_API,
});

client.interceptors.request.use(
    async (config) => {
        const user = await userManager.getUser();
        const token = user?.access_token;
        if (token && config.headers) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

client.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        if (error.response.status === 401) {
            await userManager.signinRedirect();
        }
        if (error.response.status === 403) {
            window.location.href = '/403forbidden';
        }
        return Promise.reject(error);
    }
);

export const fetchJiraConnectionsAny = async () => {
    return await client.get(`/JiraConnections/any`);
};

export const fetchJiraConnectionsList = async () => {
    return await client.get(`/JiraConnections`);
};

export const fetchJiraConnectionInfo = async (Id: number) => {
    return await client.get(`/JiraConnections/${Id}`);
};

export const addNewJiraConnection = async (data: IAddingJiraConnectionData) => {
    return await client.post(`/JiraConnections`, {
        ...data,
    });
};

export const deleteJiraConnectionInfo = async (id: number) => {
    return await client.delete(`/JiraConnections/${id}`);
};

export const generateJiraIssueDescriptionByUrl = async (data: IGenerateJiraIssuesDescription) => {
    return await client.post(`/Issues/generate/description`, {
        ...data,
    });
};