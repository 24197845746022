export interface CalendarInfoSliceInitialState {
  totalTimeLogged: string;
  initialCalendarView: keyof typeof CalendarViews;
  initialCalendarDate: Date;
  currentCalendarParams: ICurrentCalendarParams;
  isLoading: boolean;
  errorMessage: string | unknown;
}

export enum CalendarViews {
  timeGridDay = 'timeGridDay',
  timeGridWeek = 'timeGridWeek',
  dayGridMonth = 'dayGridMonth',
}

export enum EventTypes {
  Policy = 'Policy',
  Holiday = 'Holiday',
  TimeEntry = 'TimeEntry',
}

export interface FullCalendarEvent {
  id: string;
  title: string;
  start: string;
  end: string;
  borderColor: string;
  backgroundColor: string;
}

export type IGetMemberEventsData = Pick<
  ICurrentCalendarParams,
  'id' | 'start' | 'end' | 'timeZoneName'
>;

export interface ICurrentCalendarParams {
  id: number;
  start: string;
  end: string;
  timeZoneName: string;
}
