import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { TimeOffRequestsListInitialState } from './timeOffRequestsListTypes';
import { isFulfilledAction, isRejectedAction } from '../sliceHelpers';
import {
  getMemberTimeOffRequestsList,
  getTimeOffRequestsList,
  getTimeOffRequestsListInfiniteScroll,
} from './timeOffRequestsListActions';
import { ISelectOption } from 'components/shared/forms/DropdownSelect/DropdownSelect';
import { MultiValue } from 'react-select';
import { dateToTimeZone } from 'helpers/dateToTimeZone';

const initialState: TimeOffRequestsListInitialState = {
  requests: [],
  filter: {
    path: '',
    members: null,
    skip: 0,
    take: 20,
  },
  totalCount: 0,
  requestsCount: {
    approved: 0,
    submitted: 0,
    denied: 0,
    all: 0,
  },
  selectedTimeOffRequestId: -1,
  isLoading: false,
  errorMessage: '',
};

export const timeOffRequestsListSlice = createSlice({
  name: '@@timeOffRequests',
  initialState,
  reducers: {
    setSelectedMembersIds: (
      state,
      action: PayloadAction<MultiValue<ISelectOption> | null>
    ) => {
      state.filter = {
        ...state.filter,
        members: action.payload as ISelectOption[] | null,
      };
    },
    setSelectedTimeOffRequestId: (state, action: PayloadAction<number>) => {
      state.selectedTimeOffRequestId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTimeOffRequestsList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTimeOffRequestsList.fulfilled, (state, action) => {
      state.requests = action.payload.requests;
      state.requestsCount = action.payload.requestsCount;
      state.totalCount = action.payload.totalCount;
    });
    builder.addCase(getMemberTimeOffRequestsList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMemberTimeOffRequestsList.fulfilled, (state, action) => {
      state.requests = action.payload.requests;
      state.requestsCount = action.payload.requestsCount;
      state.totalCount = action.payload.totalCount;
    });
    builder.addCase(
      getTimeOffRequestsListInfiniteScroll.fulfilled,
      (state, action) => {
        state.requests.push(...action.payload.requests);
        state.requestsCount = action.payload.requestsCount;
        state.totalCount = action.payload.totalCount;
      }
    );
    builder.addMatcher(isFulfilledAction, (state) => {
      state.errorMessage = '';
      state.isLoading = false;
    });
    builder.addMatcher(isRejectedAction, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    });
  },
});

export const { setSelectedMembersIds, setSelectedTimeOffRequestId } = timeOffRequestsListSlice.actions;

export const timeOffRequestsListSelector = createSelector(
  [(state: RootState) => state.timeOffRequestsList.requests],
  (requests) => requests
);
export const timeOffRequestsCountSelector = createSelector(
  [(state: RootState) => state.timeOffRequestsList.requestsCount],
  (requestsCount) => requestsCount
);
export const isLoadingTimeOffRequestsSelector = createSelector(
  [(state: RootState) => state.timeOffRequestsList.isLoading],
  (isLoading) => isLoading
);
export const itemsTotalCountTimeOffRequestsSelector = createSelector(
  [(state: RootState) => state.timeOffRequestsList.totalCount],
  (totalCount) => totalCount
);
export const filterTimeOffRequestsSelector = createSelector(
  [(state: RootState) => state.timeOffRequestsList.filter],
  (filter) => filter
);

export const selectedTimeOffRequestIdSelector = createSelector( 
  [(state: RootState) => state.timeOffRequestsList.selectedTimeOffRequestId],
  (id) => id
);

export const selectedTimeOffRequestSelector = createSelector(
  [
    timeOffRequestsListSelector,
    selectedTimeOffRequestIdSelector,
  ],
  (timeOffRequests, id) => {
    const timeOffRequest = timeOffRequests.find((timeOffRequest) => timeOffRequest.id === id);
    
    if (timeOffRequest) {
      return timeOffRequest;
    }
  }
);