import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import {
  AddTimeFormState,
  EditTimeFormState,
  TimesheetsSliceInitialState,
} from './timesheetsTypes';
import {
  getMemberTimesheetsInfo,
  getTimesheetsFilterlist,
} from './timesheetsActions';
import { isRejectedAction } from '../sliceHelpers';
import { IProjectShortInfo } from '../projectInfo/projectInfoTypes';
import { ISelectOption } from 'components/shared/forms/DropdownSelect/DropdownSelect';

const initialState: TimesheetsSliceInitialState = {
  membersFilterList: [],
  filter: {
    selectedMemberId: null,
    selectedTimeZone: null,
  },
  memberInfo: {
    id: null,
    name: '',
    projects: [{} as IProjectShortInfo],
    timeZone: { name: '', id: '', greenwichMeanTime: '' },
  },
  addTimeFormState: null,
  editTimeFormState: null,
  isLoading: false,
  errorMessage: '',
};

export const timesheetsSlice = createSlice({
  name: '@@timesheets',
  initialState,
  reducers: {
    setSelectedMemberId: (state, action: PayloadAction<number>) => {
      state.filter.selectedMemberId = action.payload;
    },
    setSelectedTimeZone: (state, action: PayloadAction<ISelectOption>) => {
      state.filter.selectedTimeZone = action.payload;
    },
    setAddTimeFormState: (state, action: PayloadAction<AddTimeFormState>) => {
      state.addTimeFormState = action.payload;
    },
    setEditTimeFormState: (state, action: PayloadAction<EditTimeFormState>) => {
      state.editTimeFormState = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTimesheetsFilterlist.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTimesheetsFilterlist.fulfilled, (state, action) => {
      state.membersFilterList = action.payload;
      state.errorMessage = '';
      state.isLoading = false;
    });
    builder.addCase(getMemberTimesheetsInfo.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMemberTimesheetsInfo.fulfilled, (state, action) => {
      state.memberInfo = action.payload;
      state.errorMessage = '';
      state.isLoading = false;
    });
    builder.addMatcher(isRejectedAction, (state, action) => {
      state.errorMessage = action.payload;
      state.isLoading = false;
    });
  },
});

export const {
  setSelectedMemberId,
  setSelectedTimeZone,
  setAddTimeFormState,
  setEditTimeFormState,
} = timesheetsSlice.actions;

export const membersFilterListTimesheetsSelector = createSelector(
  [(state: RootState) => state.timesheets.membersFilterList],
  (filterList) => filterList
);
export const selectedMemberIdTimesheetsSelector = createSelector(
  [(state: RootState) => state.timesheets.filter.selectedMemberId],
  (id) => id
);
export const selectedTimeZoneTimesheetsSelector = createSelector(
  [(state: RootState) => state.timesheets.filter.selectedTimeZone],
  (tz) => tz
);
export const memberInfoTimesheetsSelector = createSelector(
  [(state: RootState) => state.timesheets.memberInfo],
  (info) => info
);
export const addTimeFormStateTimesheetsSelector = createSelector(
  [(state: RootState) => state.timesheets.addTimeFormState],
  (state) => state
);
export const editTimeFormStateTimesheetsSelector = createSelector(
  [(state: RootState) => state.timesheets.editTimeFormState],
  (state) => state
);
export const isLoadingTimesheetsSelector = createSelector(
  [(state: RootState) => state.timesheets.isLoading],
  (isLoading) => isLoading
);
