import React, {
  FC,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import FullCalendar, {
  DateSelectArg,
  DatesSetArg,
  DayCellContentArg,
  DayHeaderContentArg,
  EventClickArg,
  EventContentArg,
} from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { RootState, useAppDispatch } from 'app/store';
import EditTimeForm from 'components/timesheets/EditTimeForm';
import Modal from 'components/shared/Modal';
import { getMemberTimeEntries } from 'features/timeEntries/timeEntriesActions';
import { AddTimeFormState } from 'features/timesheets/timesheetsTypes';
import { CalendarViews, EventTypes } from 'features/calendarInfo/calendarInfoTypes'; 
import { 
  timeEntryByIdSelector,
  setTimeEntryIdForUpdate
} from 'features/timeEntries/timeEntriesSlice';
import {
  addTimeFormStateTimesheetsSelector,
  editTimeFormStateTimesheetsSelector,
  setAddTimeFormState,
  setEditTimeFormState,
} from 'features/timesheets/timesheetsSlice';
import {
  calendarEventsSelector,
  initialCalendarInfoViewSelector,
  setCurrentCalendarParams,
  setInitialCalendarView,
  initialCalendarInfoDateSelector,
  setInitialCalendarDate
} from 'features/calendarInfo/calendarInfoSlice'
import {
  permissionsUserSelector,
  selectInfoUserSelector,
} from 'features/user/userSlice';
import { dateToTimeZone } from 'helpers/dateToTimeZone';
import { checkIsFuture } from 'helpers/checkIsFuture';
import { setTimeInDate } from 'helpers/setTimeInDate';
import { dateToISODate } from 'helpers/dateToISODate';
import { checkIsToday } from 'helpers/checkIsToday';
import styles from './Calendar.module.scss';
import { getMemberHolidays } from 'features/holidaysList/holidaysListActions';
import { getMemberTimeOffRequestsList } from 'features/timeOffRequestsList/timeOffRequestsListActions';
import { setSelectedTimeOffRequestId, selectedTimeOffRequestSelector } from 'features/timeOffRequestsList/timeOffRequestsListSlice';
import { setSelectedHolidayId, selectedHolidaySelector } from 'features/holidaysList/holidaysListSlice';
import CalendarTimeOffView from '../CalendarTimeOffView/CalendarTimeOffView';
import CalendarHolidayView from 'components/timesheets/CalendarHolidayView';

interface CalendarProps {
  openAddTimeForm: () => void;
  selectedMemberId: number | null;
  selectedTimeZoneName: string;
  addTimeFormOpen: boolean;
}

const Calendar: FC<CalendarProps> = ({
  openAddTimeForm,
  selectedMemberId,
  selectedTimeZoneName,
  addTimeFormOpen,
}) => {
  const firstRenderRef = useRef(false);
  const refToPreventRequestOfTimeZoneInitializing = useRef(false);
  const refToPreventRequestOfTimeZoneInitializing2 = useRef(false);
  const [calendarHeight, setCalendarHeight] = useState(0);
  const calendarWrapperRef = useRef(null);
  const calendarRef = useRef<FullCalendar>(null);
  const screenWidth = window.innerWidth;
  const mobileDevices = screenWidth < 1024;
  const dispatch = useAppDispatch();
  const convertedToTimeZoneNow = dateToTimeZone(
    new Date(),
    selectedTimeZoneName
  );
  const editTimeFormState = useSelector(editTimeFormStateTimesheetsSelector);
  const addTimeFormState = useSelector(addTimeFormStateTimesheetsSelector);
  const initialView = useSelector(initialCalendarInfoViewSelector);
  const initialDate = useSelector(initialCalendarInfoDateSelector);
  const events = useSelector((state: RootState) =>
    calendarEventsSelector(state, selectedTimeZoneName)
  );
  const permissions = useSelector(permissionsUserSelector);
  const timeEntryForUpdate = useSelector((state: RootState) =>
    timeEntryByIdSelector(state, selectedTimeZoneName)
  );
  const selectedTimeOffRequest = useSelector(selectedTimeOffRequestSelector);
  const selectedHoliday = useSelector(selectedHolidaySelector);
  const userInfo = useSelector(selectInfoUserSelector);
  const [calendarKey, setCalendarKey] = useState(0);
  const [editTimeFormOpen, setEditTimeFormOpen] = useState(false);
  const [holidayViewOpen, setHolidayViewOpen] = useState(false);
  const [policyViewOpen, setPolicyViewOpen] = useState(false);
  const openEditTimeForm = () => setEditTimeFormOpen(true);
  const closeEditTimeForm = () => setEditTimeFormOpen(false);
  const defaultAddTimeFormState: AddTimeFormState = {
    calendarNow: convertedToTimeZoneNow,
    timeTrackingDate: convertedToTimeZoneNow,
    startTime: setTimeInDate(convertedToTimeZoneNow, 9, 0),
    endTime: setTimeInDate(convertedToTimeZoneNow, 10, 0),
    isBillable: true,
    selectedTimeZoneName: selectedTimeZoneName,
    //for to not update or reset project state
    project: addTimeFormState?.project || null,
    isProjectSelected: addTimeFormState?.isProjectSelected || false,
    notes: '',
    commentsLengthError: false,
  };
  const headerToolbar = !mobileDevices
    ? {
        left: 'title',
        center: 'timeGridDay,timeGridWeek,dayGridMonth',
        right: 'today prev,next',
      }
    : {
        left: 'title',
        center: 'today',
        right: 'prev,next',
      };

  const isTimeEntryEditingAvailable = permissions?.some(
    (el) => el.name === 'Update TimeEntry'
  );

  const handleDateSelect = (selectInfo: DateSelectArg) => {
    // can't click 'allDay' cells. only if it's dayGridMonth view.
    if (selectInfo.allDay && selectInfo.view.type !== 'dayGridMonth') {
      return;
    }

    // can't track time in the future day
    if (selectInfo.start < convertedToTimeZoneNow) {
      // can't track time in the future time if today. only if dayGridMonth
      // view, because its clickInfo.end it's an end
      // of all day

      if (
        selectInfo.view.type !== 'dayGridMonth' &&
        checkIsToday(selectInfo.start, selectedTimeZoneName) &&
        checkIsFuture(selectInfo.end, selectedTimeZoneName)
      ) {
        return;
      }

      // when adding time via dayGridMonth cell - default start will be
      // one hour ago and default end will be current hour
      if (selectInfo.view.type === 'dayGridMonth') {
        dispatch(
          setAddTimeFormState({
            calendarNow: convertedToTimeZoneNow,
            timeTrackingDate: setTimeInDate(selectInfo.start, 0, 0),
            startTime: setTimeInDate(selectInfo.start, 9, 0),
            endTime: setTimeInDate(selectInfo.start, 10, 0),
            isBillable: true,
            selectedTimeZoneName: selectedTimeZoneName,
            //for to not update or reset project state
            project: addTimeFormState?.project || null,
            isProjectSelected: addTimeFormState?.isProjectSelected || false,
            notes: '',
            commentsLengthError: false,
          })
        );
        openAddTimeForm();
      }

      if (
        selectInfo.view.type === 'timeGridWeek' ||
        selectInfo.view.type === 'timeGridDay'
      ) {
        dispatch(
          setAddTimeFormState({
            calendarNow: convertedToTimeZoneNow,
            timeTrackingDate: setTimeInDate(selectInfo.start, 0, 0),
            startTime: selectInfo.start,
            endTime: selectInfo.end,
            isBillable: true,
            selectedTimeZoneName: selectedTimeZoneName,
            //for to not update or reset project state
            project: addTimeFormState?.project || null,
            isProjectSelected: addTimeFormState?.isProjectSelected || false,
            notes: '',
            commentsLengthError: false,
          })
        );
        openAddTimeForm();
      }
      return;
    }
    return;
  };
  const handleEventClick = (clickInfo: EventClickArg) => {
    if (isTimeEntryEditingAvailable) {
      if (clickInfo.event.title.startsWith('Holiday:')) {
        setHolidayViewOpen(true);
        dispatch(setSelectedHolidayId(Number(clickInfo.event.id.replace(`${EventTypes.Holiday}: `, ''))));
      }
      else if (clickInfo.event.title.startsWith('Policy:')) {
        setPolicyViewOpen(true);
        dispatch(setSelectedTimeOffRequestId(Number(clickInfo.event.id.replace(`${EventTypes.Policy}: `, ''))));
      }
      else {
        openEditTimeForm();
        dispatch(setTimeEntryIdForUpdate(Number(clickInfo.event.id.replace(`${EventTypes.TimeEntry}: `, ''))));
      }
    }
  };
  const renderEventContent = (eventContent: EventContentArg) => {
    if (
      eventContent.event.title.startsWith('Policy:') ||
      eventContent.event.title.startsWith('Holiday:')
    ) {
      return (
        <div className={styles.allDayCustomEvent}>
          <div className={styles.description}>{eventContent.event.title}</div>
        </div>
      );
    } else {
      return (
        <div
          className={`${styles.customEvent} ${
            !isTimeEntryEditingAvailable ? styles.customEventViewOnly : ''
          }`}
        >
          {!eventContent.event.allDay &&
            eventContent.event.start &&
            eventContent.event.end && (
              <div className={styles.time}>
                {format(eventContent.event.start, 'hh:mm aaa')} -{' '}
                {format(eventContent.event.end, 'hh:mm aaa')}
              </div>
            )}
          <div className={styles.description}>{eventContent.event.title}</div>
        </div>
      );
    }
  };
  const dayHeaderContent = ({ date, isToday, view }: DayHeaderContentArg) => {
    if (view.type === 'dayGridMonth') {
      return <div>{`${format(dateToTimeZone(date, 'Etc/UTC'), 'EEE')}`}</div>;
    }

    return (
      <div className={styles.headerWrapper}>
        <div className={`${styles.dateOfMonth} ${isToday ? styles.today : ''}`}>
          {`${format(date, 'dd')}`}
        </div>
        <div className={`${styles.monthAndDay}`}>
          <div>{`${format(date, 'MMM')} `}</div>
          <div className={styles.month}>{`${format(date, 'EEE')}`}</div>
        </div>
      </div>
    );
  };
  const dayCellContent = ({
    dayNumberText,
    isToday,
    view,
  }: DayCellContentArg) => {
    return (
      <div
        className={`${
          isToday && view.type === 'dayGridMonth'
            ? styles.dayCellToday
            : styles.dayCell
        }`}
      >
        {dayNumberText}
      </div>
    );
  };
  const datesSet = (dateInfo: DatesSetArg) => {
    if(selectedMemberId) {
      dispatch(
        getMemberTimeEntries({
          id: selectedMemberId,
          start: dateToISODate(dateInfo.start),
          end: dateToISODate(dateInfo.end),
          timeZoneName: selectedTimeZoneName,
        })
      );
      dispatch(
        getMemberHolidays({
          accountId: selectedMemberId,
          start: dateToISODate(dateInfo.start),
          end: dateToISODate(dateInfo.end),
        })
      );
      dispatch(
        getMemberTimeOffRequestsList({
          path: "/approved",
          accountId: selectedMemberId,
          start: dateToISODate(dateInfo.start),
          end: dateToISODate(dateInfo.end),
        })
      );
    }
      
    if (calendarRef.current) {
      const calendarAPI = calendarRef.current.getApi();
      selectedMemberId &&
        dispatch(
          setCurrentCalendarParams({
            id: selectedMemberId,
            start: dateToISODate(calendarAPI.view.activeStart),
            end: dateToISODate(calendarAPI.view.activeEnd),
            timeZoneName: selectedTimeZoneName,
          })
        );

      dispatch(
        setAddTimeFormState({
          ...defaultAddTimeFormState,
          timeTrackingDate: mobileDevices
            ? dateInfo.start
            : convertedToTimeZoneNow,
          startTime: setTimeInDate(
            mobileDevices ? dateInfo.start : convertedToTimeZoneNow,
            9,
            0
          ),
          endTime: setTimeInDate(
            mobileDevices ? dateInfo.start : convertedToTimeZoneNow,
            10,
            0
          ),
        })
      );
    }
  };
  const memoizedNow = useMemo(() => {
    return convertedToTimeZoneNow;
  }, [selectedTimeZoneName]);

  useLayoutEffect(() => {
    const updateBlockHeight = () => {
      const windowHeight = window.innerHeight;
      const calendarBlock = document.getElementById('calendar');

      if (calendarBlock) {
        const blockPosition = calendarBlock.getBoundingClientRect().top;
        const newBlockHeight = windowHeight - blockPosition;
        setCalendarHeight(newBlockHeight - (mobileDevices ? 107 : 115));
      }
    };

    updateBlockHeight();

    window.addEventListener('scroll', updateBlockHeight);
    window.addEventListener('resize', updateBlockHeight);

    // Очистка слушателей при размонтировании компонента
    return () => {
      window.removeEventListener('scroll', updateBlockHeight);
      window.removeEventListener('resize', updateBlockHeight);
    };
  }, []);

  useEffect(() => {
    if (
      calendarRef.current &&
      refToPreventRequestOfTimeZoneInitializing.current
    ) {
      const calendarAPI = calendarRef.current.getApi();
      calendarAPI.changeView(calendarAPI.getCurrentData().currentViewType);
    }
    refToPreventRequestOfTimeZoneInitializing.current = true;
  }, [selectedMemberId]);

  //this useEffect updates entire Calendar component to refresh NowIndicator position
  useEffect(() => {
    if (
      calendarRef.current &&
      refToPreventRequestOfTimeZoneInitializing2.current
    ) {
      const calendarAPI = calendarRef.current.getApi();
      dispatch(
        setInitialCalendarView(
          calendarAPI.getCurrentData()
            .currentViewType as keyof typeof CalendarViews
        )
      );
      dispatch(
        setInitialCalendarDate(
          calendarAPI.getCurrentData().currentDate
        )
      );
      
      calendarAPI.setOption('now', convertedToTimeZoneNow);
      setCalendarKey((prevKey) => prevKey + 1);
    }
    refToPreventRequestOfTimeZoneInitializing2.current = true;
  }, [selectedTimeZoneName]);

  useEffect(() => {
    if (calendarRef.current) {
      const calendarAPI = calendarRef.current.getApi();
      selectedMemberId &&
        dispatch(
          setCurrentCalendarParams({
            id: selectedMemberId,
            start: dateToISODate(calendarAPI.view.activeStart),
            end: dateToISODate(calendarAPI.view.activeEnd),
            timeZoneName: selectedTimeZoneName,
          })
        );
      dispatch(setAddTimeFormState(defaultAddTimeFormState));
    }
  }, [calendarRef.current]);

  useEffect(() => {
    if (!addTimeFormOpen && firstRenderRef.current && calendarRef.current) {
      const calendarAPI = calendarRef.current.getApi();
      const closeAddTimeFormTimeOut = setTimeout(() => {
        dispatch(
          setAddTimeFormState({
            ...defaultAddTimeFormState,
            timeTrackingDate: mobileDevices
              ? calendarAPI.view.currentStart
              : convertedToTimeZoneNow,
            startTime: setTimeInDate(
              mobileDevices
                ? calendarAPI.view.currentStart
                : convertedToTimeZoneNow,
              9,
              0
            ),
            endTime: setTimeInDate(
              mobileDevices
                ? calendarAPI.view.currentStart
                : convertedToTimeZoneNow,
              10,
              0
            ),
          })
        );
      }, 300);
      return () => {
        clearTimeout(closeAddTimeFormTimeOut);
      };
    }
    firstRenderRef.current = true;
  }, [addTimeFormOpen, calendarRef.current]);

  useEffect(() => {
    if (timeEntryForUpdate) {
      dispatch(
        setEditTimeFormState({
          timeEntryId: timeEntryForUpdate.id,
          calendarNow: convertedToTimeZoneNow,
          timeTrackingDate: timeEntryForUpdate.start,
          startTime: timeEntryForUpdate.start,
          endTime: timeEntryForUpdate.end,
          isBillable: timeEntryForUpdate.isBillable,
          selectedTimeZoneName: selectedTimeZoneName,
          project: {
            label: timeEntryForUpdate.projectName,
            value: String(timeEntryForUpdate.projectId),
          },
          notes: timeEntryForUpdate.note,
          isProjectSelected: true,
          commentsLengthError: false,
        })
      );
    }
  }, [timeEntryForUpdate]);

  useEffect(() => {
    const earliestTimeEntry = events.sort((a, b) => {
      return new Date(a.start).getHours() - new Date(b.start).getHours();
    })[0];

    const scrollTime = earliestTimeEntry
      ? `${String(new Date(earliestTimeEntry.start).getHours()).padStart(
          2,
          '0'
        )}:${String(new Date(earliestTimeEntry.start).getMinutes()).padStart(
          2,
          '0'
        )}:00`
      : '08:00:00';

    if (calendarRef.current) {
      const calendarAPI = calendarRef.current.getApi();
      calendarAPI.scrollToTime(scrollTime);
    }
  }, [events]);

  return (
    <>
      <div
        id="calendar"
        className={styles.calendar}
        style={{ height: `${calendarHeight}px` }}
        ref={calendarWrapperRef}
      >
        <FullCalendar
          key={calendarKey}
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          eventContent={renderEventContent}
          eventClick={handleEventClick}
          select={handleDateSelect}
          initialView={initialView}
          initialDate={initialDate}
          contentHeight={calendarHeight}
          headerToolbar={headerToolbar}
          eventDisplay={'block'}
          eventMinHeight={50}
          dayMaxEventRows={2}
          eventMaxStack={1}
          scrollTime={'08:00:00'}
          eventTimeFormat={{
            hour: 'numeric',
            minute: '2-digit',
            meridiem: 'short',
          }}
          fixedWeekCount={false}
          showNonCurrentDates={false}
          firstDay={userInfo?.firstDayOfWeek ?? 1}
          slotDuration={'00:30:00'}
          slotLabelInterval={'01:00'}
          expandRows={true}
          slotEventOverlap={true}
          displayEventTime
          displayEventEnd
          selectable={true}
          allDaySlot={false}
          weekends
          stickyHeaderDates
          titleFormat={{ year: 'numeric', month: 'short', day: 'numeric' }}
          views={{
            timeGridWeek: {
              dayHeaderFormat: {
                day: 'numeric',
                weekday: 'short',
                month: 'short',
              },
            },
            timeGridDay: {
              dayHeaderFormat: {
                day: 'numeric',
                weekday: 'long',
                month: 'short',
              },
            },
          }}
          dayHeaderContent={dayHeaderContent}
          dayCellContent={dayCellContent}
          allDayText={''}
          events={events}
          datesSet={datesSet}
          nowIndicator
          now={memoizedNow}
        />

        {editTimeFormState && (
          <Modal
            title="Edit time"
            open={editTimeFormOpen}
            onClose={closeEditTimeForm}
          >
            <EditTimeForm
              data={editTimeFormState}
              handleClose={closeEditTimeForm}
            />
          </Modal>
        )}
        {selectedHoliday && (
          <Modal
            title={EventTypes.Holiday}
            open={holidayViewOpen}
            onClose={() => setHolidayViewOpen(false)}
          >
            <CalendarHolidayView holiday={selectedHoliday} />
          </Modal>
        )}
        {selectedTimeOffRequest && (
          <Modal
            title={EventTypes.Policy}
            open={policyViewOpen}
            onClose={() => setPolicyViewOpen(false)}
          >
            <CalendarTimeOffView timeOff={selectedTimeOffRequest} />
          </Modal>
        )}
      </div>
    </>
  );
};

export default Calendar;
