import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import {
  ITimeEntryData,
  TimeEntriesSliceInitialState,
} from './timeEntriesTypes';
import {
  addTimeEntry,
  changeTimeEntry,
  getMemberTimeEntries,
} from './timeEntriesActions';
import { dateToTimeZone } from 'helpers/dateToTimeZone';
import { isFulfilledAction, isRejectedAction } from '../sliceHelpers';
import { ICurrentCalendarParams } from 'features/calendarInfo/calendarInfoTypes'; 

const initialState: TimeEntriesSliceInitialState = {
  timeEntries: [],
  selectedTimeEntryId: -1,
  isLoading: false,
  errorMessage: '',
};

export const timeEntriesSlice = createSlice({
  name: '@@timeEntries',
  initialState,
  reducers: {
    setTimeEntryIdForUpdate: (state, action: PayloadAction<number>) => {
      state.selectedTimeEntryId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getMemberTimeEntries.fulfilled,
      (
        state,
        action: PayloadAction<{
          timeEntries: ITimeEntryData[];
          params: ICurrentCalendarParams;
        }>
      ) => {
        state.timeEntries = action.payload.timeEntries;
      }
    );
    builder.addCase(getMemberTimeEntries.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addTimeEntry.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(changeTimeEntry.pending, (state) => {
      state.isLoading = true;
    });
    builder.addMatcher(isFulfilledAction, (state) => {
      state.errorMessage = '';
      state.isLoading = false;
    });
    builder.addMatcher(isRejectedAction, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    });
  },
});

export const { setTimeEntryIdForUpdate } = timeEntriesSlice.actions;

export const timeEntriesSelector = createSelector(
  [(state: RootState) => state.timeEntries.timeEntries],
  (timeEntries) => timeEntries
);

export const isLoadingTimeEntriesSelector = createSelector(
  [(state: RootState) => state.timeEntries.isLoading],
  (isLoading) => isLoading
);

export const selectedTimeEntryIdSelector = createSelector( 
  [(state: RootState) => state.timeEntries.selectedTimeEntryId],
  (id) => id
);

export const timeEntryByIdSelector = createSelector(
  [
    timeEntriesSelector,
    selectedTimeEntryIdSelector,
    (state: RootState, memberTimeZone: string) => memberTimeZone,
  ],
  (timeEntries, id, memberTimeZone) => {
    const timeEntry = timeEntries.find((timeEntry) => timeEntry.id === id);
    if (timeEntry) {
      return {
        ...timeEntry,
        allDay: false,
        start: dateToTimeZone(timeEntry.start, memberTimeZone),
        end: dateToTimeZone(timeEntry.end, memberTimeZone),
      };
    }
  }
);
