import client from './axiosInstance';
import {
  IDeleteRateHistoryEntryData,
  IMemberInfoUpdatingData,
  UpdateRateHistoryEntryData,
} from 'features/memberInfo/memberInfoTypes';

export const fetchOrganizationMemberInfo = async (memberId: number) => {
  return await client.get(`/members/${memberId}`);
};

export const updateMemberInfo = async (data: IMemberInfoUpdatingData) => {
  return await client.patch(`/members/${data.id}`, {
    ...data,
  });
};

export const addPayRateHistoryEntry = async (data: {
  memberId: number;
  payload: UpdateRateHistoryEntryData;
}) => {
  if (data.payload.paymentRateType === 'billRate') {
    return await client.post(`/pay-amounts/bill-rate/${data.memberId}`, {
      rate: data.payload.rate,
      startDate: data.payload.startDate,
    });
  } else {
    return await client.post(`/pay-amounts/pay-rate/${data.memberId}`, {
      rate: data.payload.rate,
      payType: data.payload.type,
      startDate: data.payload.startDate,
    });
  }
};

export const updatePayRateHistoryEntry = async (data: {
  payload: UpdateRateHistoryEntryData;
}) => {
  if (data.payload.paymentRateType === 'billRate') {
    return await client.patch(`/pay-amounts/bill-rate/${data.payload.id}`, {
      rate: data.payload.rate,
      startDate: data.payload.startDate,
    });
  } else {
    return await client.patch(`/pay-amounts/pay-rate/${data.payload.id}`, {
      rate: data.payload.rate,
      payType: data.payload.type,
      startDate: data.payload.startDate,
    });
  }
};

export const deletePayRateHistoryEntry = async (
  data: IDeleteRateHistoryEntryData
) => {
  return await client.delete(
    `/pay-amounts/${data.payAmountType}/${data.entryId}`
  );
};
