import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { IInvitesListsSliceInitialState } from './invitesListTypes';
import { getInvites, getInvitesInfiniteScroll } from './invitesListActions';
import { isFulfilledAction, isRejectedAction } from '../sliceHelpers';

const initialState: IInvitesListsSliceInitialState = {
  invites: [
    {
      id: 1,
      email: 'invite@gmail.com',
      role: 'User',
      status: 'Pending',
      projectsIds: []
    },
  ],
  totalCount: 1,
  filter: {
    skip: 0,
    take: 20,
  },
  isLoading: false,
  errorMessage: '',
};

export const invitesListSlice = createSlice({
  name: '@@invitesList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getInvites.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getInvites.fulfilled, (state, action) => {
      state.invites = action.payload.list;
      state.totalCount = action.payload.count;
    });
    builder.addCase(getInvitesInfiniteScroll.fulfilled, (state, action) => {
      state.invites.push(...action.payload.list);
      state.totalCount = action.payload.count;
    });
    builder.addMatcher(isFulfilledAction, (state) => {
      state.errorMessage = '';
      state.isLoading = false;
    });
    builder.addMatcher(isRejectedAction, (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
    });
  },
});

export const isLoadingInvitesSelector = createSelector(
  [(state: RootState) => state.invitesList.isLoading],
  (isLoading) => isLoading
);
export const listInvitesSelector = createSelector(
  [(state: RootState) => state.invitesList.invites],
  (list) => list
);
export const itemsTotalCountInvitesSelector = createSelector(
  [(state: RootState) => state.invitesList.totalCount],
  (totalCount) => totalCount
);
export const filterInvitesSelector = createSelector(
  [(state: RootState) => state.invitesList.filter],
  (filter) => filter
);
