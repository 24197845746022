import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IoPersonCircle } from 'react-icons/io5';
import { useAppDispatch } from 'app/store';
import EditAccountForm from 'components/account/EditAccountForm';
import { selectInfoUserSelector } from 'features/user/userSlice';
import { IUpdateUserInfo } from 'features/user/userTypes';
import { getTimeZones, updateUserInfo } from 'features/user/userActions';
import styles from './Account.module.scss';
import {Link} from "react-router-dom";

const Account: FC = () => {
  const userInfo = useSelector(selectInfoUserSelector);
  const dispatch = useAppDispatch();

  const handleSubmit = (data: IUpdateUserInfo) => {
    dispatch(updateUserInfo(data));
  };

  useEffect(() => {
    dispatch(getTimeZones());
  }, []);

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.accountWrapper}>
          <div className={styles.userAvatar}>
            <IoPersonCircle />
          </div>
          <div className={styles.actions}>
            {/* <a
              href={`${process.env.REACT_APP_OIDC_AUTHORITY}/change-email`}
              aria-label="Change email page link"
            >
              Change email
            </a>{' '}
            <a
              href={`${process.env.REACT_APP_OIDC_AUTHORITY}/change-password`}
              aria-label="Change password page link"
            >
              Change password
            </a> */}
            <Link to={'jira'}>Jira connections</Link>
            {/*<a*/}
            {/*  href={`${process.env.REACT_APP_OIDC_AUTHORITY}/delete-account`}*/}
            {/*  aria-label="Delete account page link"*/}
            {/*>*/}
            {/*  Delete account*/}
            {/*</a>*/}
          </div>
        </div>
        <div className={styles.editAccountWrapper}>
          <h4>Edit Information</h4>
          {userInfo && (
            <EditAccountForm user={userInfo} handleSubmit={handleSubmit} />
          )}
        </div>
      </div>
    </>
  );
};

export default Account;
