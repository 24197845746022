import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'app/store';
import ActionsPopover from 'components/shared/ActionsPopover';
import ConfirmDialog from 'components/shared/ConfirmDialog';
import styles from 'components/shared/tables/infiniteScrollTable.module.scss';
import {listJiraConnectionsSelector} from "../../../features/jira/jiraSlice";
import {IJiraConnectionItem} from "../../../features/jira/jiraTypes";
import {removeJiraConnection} from "../../../features/jira/jiraActions";

const jiraConnectionsTableColumns = [
  { name: 'Jira URL' },
  { name: 'Username' },
  { name: 'Jira token' },
  { name: 'Actions' },
];

const JiraConnectionsTable: FC = () => {
  const jiraConnections = useSelector(listJiraConnectionsSelector);
  
  const dispatch = useAppDispatch();
  
  const [jiraConnectionIdForDelete, setJiraConnectionIdForDelete] = useState<number | undefined>(undefined);
  const [confirmDeleteJiraConnection, setConfirmDeleteJiraConnection] = useState(false);
  const handleRemove = (id: number) => {
    setJiraConnectionIdForDelete(id);
    setConfirmDeleteJiraConnection(true);
  };

  const JiraConnectionsTableItem: FC<IJiraConnectionItem> = (
      content
  ) => {
    return (
      <div key={content.id}>
        <div className={`${styles.fRow} ${styles.fBodyRow}`}>
          <div className={`${styles.fCell} ${styles.fBodyCell}`}>
            <span className={`${styles.fBodyCellContent}`}>{content.serverUrl}</span>
          </div>
          <div className={`${styles.fCell} ${styles.fBodyCell}`}>
            <span className={`${styles.fBodyCellContent}`}>{content.username}</span>
          </div>
          <div className={`${styles.fCell} ${styles.fBodyCell}`}>
            <span className={`${styles.fBodyCellContent}`}>******************</span>
          </div>
          <div className={`${styles.fCell} ${styles.fBodyCell}`}>
            <ActionsPopover>
              <li onClick={() => handleRemove(content.id)}>
                <button aria-label={'Delete connection'}>Delete connection</button>
              </li>
            </ActionsPopover>
          </div>
        </div>
      </div>
    );
  };

  return (
      <>
        <div className={styles.fTable}>
          <div className={`${styles.fRow} ${styles.fHeaderRow}`}>
          {jiraConnectionsTableColumns.map((col) => (
            <div
              key={col.name}
              className={`${styles.fCell} ${styles.fHeaderCell}`}
            >
              {col.name}
            </div>
          ))}
        </div>
        <div className={styles.fTableBody}>
          {
            jiraConnections.map(connection => JiraConnectionsTableItem(connection))
          }
        </div>
      </div>
      {jiraConnectionIdForDelete && (
        <ConfirmDialog
          open={confirmDeleteJiraConnection}
          title="Confirm"
          description="Delete this connection?"
          acceptBtnText="Delete"
          declineBtnText="Cancel"
          onClose={() => setConfirmDeleteJiraConnection(false)}
          onAccept={() => {
            dispatch(removeJiraConnection(jiraConnectionIdForDelete));
          }}
        />
      )}
    </>
  );
};

export default JiraConnectionsTable;
