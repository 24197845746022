import {createAsyncThunk} from "@reduxjs/toolkit";
import {AppDispatch, RootState} from "../../app/store";
import {MyKnownError, thunkErrorHandler} from "../thunkError";
import {IAddingJiraConnectionData, IGenerateJiraIssuesDescription, IJiraConnectionItem} from "./jiraTypes";
import {
    addNewJiraConnection,
    deleteJiraConnectionInfo,
    fetchJiraConnectionInfo,
    fetchJiraConnectionsAny,
    fetchJiraConnectionsList, generateJiraIssueDescriptionByUrl
} from "../../api/jira";
import {toast} from "react-toastify";
import {removeJiraConnectionReducer} from "./jiraSlice";

export const anyJiraConnections = createAsyncThunk<
    boolean,
    void,
    {
        dispatch: AppDispatch;
        rejectValue: string | unknown;
    }
>('@@jira/anyJiraConnections', async (param, thunkApi) => {
    try {
        const res = await fetchJiraConnectionsAny();
        return res.data;
    } catch (err) {
        return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
    }
});

export const getJiraConnectionInfo = createAsyncThunk<
    IJiraConnectionItem,
    number,
    {
        dispatch: AppDispatch;
        rejectValue: string | unknown;
    }
>('@@jira/getJiraConnectionInfo', async (id, thunkApi) => {
    try {
        const res = await fetchJiraConnectionInfo(id);
        return res.data;
    } catch (err) {
        return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
    }
});

export const getJiraConnectionsList = createAsyncThunk<
    IJiraConnectionItem[],
    void,
    {
        dispatch: AppDispatch;
        rejectValue: string | unknown;
    }
>('@@jira/getJiraConnectionsList', async (param, thunkApi) => {
    try {
        const res = await fetchJiraConnectionsList();
        return res.data;
    } catch (err) {
        return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
    }
});

export const addJiraConnection = createAsyncThunk<
    IJiraConnectionItem,
    IAddingJiraConnectionData,
    {
        dispatch: AppDispatch;
        rejectValue: string | unknown;
    }
>('@@jira/addJiraConnection', async (param, thunkApi) => {
    try {
        const res = await addNewJiraConnection(param);
        if (res.status === 201 || res.status === 200) {
            thunkApi.dispatch(getJiraConnectionsList());
            toast.success('New Connection Created!');
        }
        return res.data;
    } catch (err) {
        console.log(err)
        return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
    }
});

export const removeJiraConnection = createAsyncThunk<
    void,
    number,
    {
        state: RootState;
        dispatch: AppDispatch;
        rejectValue: string | unknown;
    }
>('@@jira/deleteJiraConnection', async (id, thunkApi) => {
    try {
        const res = await deleteJiraConnectionInfo(id);
        if (res.status === 204) {
            thunkApi.dispatch(removeJiraConnectionReducer(id));
            toast.success('jira connection deleted.');
        }
    } catch (err) {
        return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
    }
});

export const generateJiraIssueDescription = createAsyncThunk<
    string,
    IGenerateJiraIssuesDescription,
    {
        dispatch: AppDispatch;
        rejectValue: string;
    }
>('@@jira/generateJiraIssueDescription', async (url, thunkApi) => {
    try {
        const res = await generateJiraIssueDescriptionByUrl(url);
        if(res.status === 200) {
            return res.data;
        }
    } catch (err) {
        return thunkErrorHandler(err as MyKnownError, thunkApi.rejectWithValue);
    }
});