import client from './axiosInstance';
import {
  IAddingHolidayData,
  IFetchHolidaysParams,
  IFetchMemberHolidaysParams,
} from 'features/holidaysList/holidaysListTypes';

export const fetchHolidaysList = async (
  params: IFetchHolidaysParams | void
) => {
  if (params) {
    const { searchValue, skip, take } = params;
    const searchStringQuery =
      searchValue && searchValue.length > 0 ? `search=${searchValue}` : '';
    const skipQuery = `${
      searchValue && searchValue.length > 0 ? '&' : ''
    }skip=${skip}`;
    const takeQuery = `&take=${take}`;
    const queryStr = `?${searchStringQuery}${skipQuery}${takeQuery}`;
    return await client.get(`/time-off/holidays${queryStr}`);
  } else {
    return await client.get(`/time-off/holidays`);
  }
};

export const fetchMemberHolidays = async (
  params: IFetchMemberHolidaysParams
) => {
  const { start, end, accountId } = params;
  const startDateQuery =
  start.length > 0 ? `StartDate=${start}` : '';
  const endDateQuery = `${
    start.length > 0 ? '&' : ''
  }EndDate=${end}`;
  const queryStr = `?${startDateQuery}${endDateQuery}`;
  return await client.get(`/time-off/holidays/members/${accountId}${queryStr}`);
};

export const addNewHoliday = async (data: IAddingHolidayData) => {
  return await client.post(`/time-off/holidays`, {
    ...data,
  });
};

export const deleteHoliday = async (holidayId: number) => {
  return await client.delete(`/time-off/holidays/${holidayId}`);
};
