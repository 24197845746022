import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { isFulfilledAction, isRejectedAction } from '../sliceHelpers';
import {IJiraConnectionsSliceInitialState} from "./jiraTypes";
import {anyJiraConnections, getJiraConnectionsList} from "./jiraActions";
import {projectsListSlice} from "../projectsList/projectsListSlice";

const initialState: IJiraConnectionsSliceInitialState = {
    jiraConnections: [],
    anyJiraConnections: false,
    isLoading: false,
    errorMessage: '',
};

export const jiraConnectionSlice = createSlice({
    name: '@@jira',
    initialState,
    reducers: {
        removeJiraConnectionReducer: (state, action: PayloadAction<number>) => {
            state.jiraConnections = state.jiraConnections.filter((p) => p.id !== action.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getJiraConnectionsList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getJiraConnectionsList.fulfilled, (state, action) => {
            state.jiraConnections = action.payload;
        });
        builder.addCase(anyJiraConnections.fulfilled, (state, action) => {
            state.anyJiraConnections = action.payload;
        });
        builder.addMatcher(isFulfilledAction, (state) => {
            state.errorMessage = '';
            state.isLoading = false;
        });
        builder.addMatcher(isRejectedAction, (state, action) => {
            state.isLoading = false;
            state.errorMessage = action.payload;
        });
    },
});

export const { removeJiraConnectionReducer } = jiraConnectionSlice.actions;
export const isLoadingJiraConnectionSelector = createSelector(
    [(state: RootState) => state.jira.isLoading],
    (isLoading) => isLoading
);
export const listJiraConnectionsSelector = createSelector(
    [(state: RootState) => state.jira.jiraConnections],
    (list) => list
);

export const anyJiraConnectionsSelector = createSelector(
    [(state: RootState) => state.jira.anyJiraConnections],
    (anyJiraConnections) => anyJiraConnections
);
