import React, { FC, InputHTMLAttributes } from 'react';
import styles from './CustomInput.module.scss';

interface CustomInputProps extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    error?: boolean;
    errorMessage?: string;
}

const CustomInput: FC<CustomInputProps> = ({
    label,
    error,
    errorMessage,
    ...props
}) => {
    return (
        <div className={styles.wrapper}>
            {label && <label htmlFor={label} className={styles.label}>{label}</label>}
            <input
                type="text"
                className={`form-control ${error && 'errorInput'}`}
                {...props}
            />
            {error && errorMessage && (
                <span className={styles.errorMessage}>{errorMessage}</span>
            )}
        </div>
    );
};

export default CustomInput;