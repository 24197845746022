import React, {FC, useEffect, useState} from "react";
import {useAppDispatch} from "app/store";
import {useSelector} from "react-redux";
import {isLoadingJiraConnectionSelector, listJiraConnectionsSelector} from "features/jira/jiraSlice";
import {addJiraConnection, getJiraConnectionsList} from "features/jira/jiraActions";
import Preloader from "components/shared/Preloader";
import EmptyStatePlaceholder from "components/shared/EmptyStatePlaceholder";
import JiraConnectionsTable from "components/account/JiraConnectionsTable"
import styles from "./JiraConnections.module.scss";
import Button from "components/shared/Button";
import Modal from "components/shared/Modal";
import {IAddingJiraConnectionData} from "features/jira/jiraTypes";
import AddJiraConnectionForm from "components/account/AddJiraConnectionForm";

const JiraConnections: FC = () => {
    const isLoading = useSelector(isLoadingJiraConnectionSelector);
    const jiraConnections = useSelector(listJiraConnectionsSelector);

    const dispatch = useAppDispatch();

    const [addJiraConnectionFormOpen, setAddJiraConnectionFormOpen] = useState(false);
    const openAddJiraConnectionForm = () => setAddJiraConnectionFormOpen(true);
    const closeAddJiraConnectionForm = () => setAddJiraConnectionFormOpen(false);

    const onSubmit = (data: IAddingJiraConnectionData) => {
        dispatch(addJiraConnection(data));
    };
    
    useEffect(() => {
        dispatch(getJiraConnectionsList());
    }, []);

    const emptyMessage = 'No connections here.';
    
    return(
        <>
            {isLoading 
                ? (<Preloader />) 
                : (<div className={styles.wrapper}>
                    <div className={styles.controls}>
                        <div className={styles.btnWrapper}>
                            <Button
                                variant="primary"
                                onClick={openAddJiraConnectionForm}
                                aria-label="Open jira connection adding form."
                            >
                                Add connection
                            </Button>
                        </div>
                    </div>
                    {jiraConnections.length ? (
                        <JiraConnectionsTable/>
                    ) : (
                        <EmptyStatePlaceholder>{emptyMessage}</EmptyStatePlaceholder>
                    )}
                    <Modal
                        title="Add Jira connection"
                        open={addJiraConnectionFormOpen}
                        onClose={closeAddJiraConnectionForm}
                    >
                        <AddJiraConnectionForm
                            onSubmit={onSubmit}
                            closeAddJiraConnectionForm={closeAddJiraConnectionForm}
                        />
                    </Modal>
                </div>)
            }
        </>
    );
}

export default JiraConnections;