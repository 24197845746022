import React, { FC } from 'react';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { memberInfoTimesheetsSelector } from 'features/timesheets/timesheetsSlice';
import { IHolidaysListItem } from 'features/holidaysList/holidaysListTypes';
import styles from './CalendarHolidayView.module.scss';

interface CalendarHolidayViewProps {
  holiday: IHolidaysListItem;
}

const CalendarHolidayView: FC<CalendarHolidayViewProps> = ({
  holiday,
}) => {
  const memberInfo = useSelector(memberInfoTimesheetsSelector);
  const { name: memberName } = memberInfo;
  const {
    id,
    name,
    members,
    date,
  } = holiday;

  return (
    <div className={styles.wrapper}>
      <span className="input-label">Member</span>
      <div>{memberName}</div>

      <span className="input-label">Holiday name</span>
      <div>{name}</div>

      <span className="input-label">Holiday date</span>
      <div>
        {/* Ненужное переформатирование */}
        {format(new Date(date), 'EEE, MMM dd yyyy') }
        <br />
      </div>
    </div>
  );
};

export default CalendarHolidayView;
